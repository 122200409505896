import React, {useState} from 'react';
import ProjectSection from '../components/ProjectSection';
import ScrollToTop from '../components/ScrollToTop';
import { projectData } from '../components/InfoSection/Data';

const Project = ({ match } ) => {

    const {
        params: { projectId },
      } = match;

 /*    const [current, setCurrent] = useState(projectId);
    const length = projectData.length;

    const projectNext = () => {
        setCurrent(current === length - 1 ? 0 : current +1);
    };

    const projectPrev = () => {
        setCurrent(current === 0 ? length - 1 : current -1);
    } */


    let projectPrev = parseInt(projectId) - 1;
    let projectNext = parseInt(projectId) + 1;

    if (projectId <= 1){
        projectPrev = projectData.length;
    }

    if (projectId >= projectData.length){
        projectNext = 1;
    }

    let projectNamePrev = projectData[projectPrev-1].headLine;
    let projectNameNext = projectData[projectNext-1].headLine;

    let projectImagePrev = projectData[projectPrev-1].img;
    let projectImageNext = projectData[projectNext-1].img;
          
    return (
        <>
            <ScrollToTop/>
            <ProjectSection {...projectData[projectId-1]}
                            topLine={"0"+projectId}
                            id={"project"+projectId}
                            total={projectData.length}
                            prev={projectPrev}
                            prevCopy={projectNamePrev}
                            prevImage={projectImagePrev}
                            next={projectNext}
                            nextCopy={projectNameNext}
                            nextImage={projectImageNext}
                            initial={{ opacity: 0, y: 16}}
                            animate={{ opacity: 1, y: 0}}
                            exit={{ opacity: 0, y: -16 }}
                            transition={{ duration: 1 }}
            />
        </>
        
    )
}

export default Project;