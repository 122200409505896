import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link as LinkR } from 'react-router-dom';


export const InfoWrapper = styled(motion.div)`
    display: grid;
`;

export const InfoRow = styled.div`
    display: grid;
    
`;

export const Title = styled(motion.h1)`
    grid-column: 1 / span 12;
    font-weight: 400;
    font-size: 32px;

    &:after {
        content: '${props => props.content}';
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        color: #a9a9a9;
        letter-spacing: 0.1rem;
        position: relative;
        display: inline-block;
        vertical-align: super;
        margin-left: 4px;
    }

    @media screen and (min-width: 769px){
        margin-bottom: 0;
    }

    @media screen and (max-width: 768px){
        margin-bottom: 16px;
    }
`;

export const TopLabel = styled.p`
    font-size: 8px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    text-align: ${props => props.textAlign};
    color: #191919;
`;

export const Heading = styled.h1`
    margin: 8px 0 16px;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 500;
    color: #191919;
    grid-column: 1 / span 2;
    /* border-top: 1px solid #eeeeee;
    padding-top: 16px; */
    text-decoration: underline;

    @media screen and (max-width: 480px){
        font-size: 16px;
    }
`;

export const Subtitle = styled.p`
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 2;
    color: #777777;
    grid-column: 1 / span 2;
    max-height: 64px;
    overflow: hidden;
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    grid-column: 1 / span 2;
`;

export const ImgWrap = styled.div`  
    position: relative;
    grid-column: 1 / span 2;
    margin: 8px 0 16px;
    overflow: hidden;

    @media screen and (min-width: 769px){
        height: 256px;
    }

    @media screen and (max-width: 768px){
        height: 256px;
        width: 100%;
    }
`;

export const Img = styled(motion.div)`
    width: 100%;
    padding-right: 0;
    border-radius: 2px;
    /* box-shadow: 0 16px 24px rgba(0,0,0,0.5); */
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transition: transform 0.9s;
    transition-timing-function: cubic-bezier(.1,.9,1,1);
    box-shadow: inset 0 0 16px rgba(0,0,0,0.1);

    &.active {
        transition: 0.2s ease-in-out;
        border: radius 24px;
    }
`;

export const Video = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: transform 0.9s;
    transition-timing-function: cubic-bezier(.1,.9,1,1);
    /* background: #000; */
`;

export const BackgroundWrap = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    /* background-color: #000000; */
`;

export const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(8px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
`;

export const InfoContainer = styled(motion.custom(LinkR))`
    color: #191919;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    grid-column: span 4;

    &:hover {
        ${Img} {
          transform: scale(1.05);
          transition: transform 0.6s;
          transition-timing-function: cubic-bezier(.1,.9,1,1);
        }
        ${Video} {
            transform: scale(1.05);
            transition: transform 0.6s;
            transition-timing-function: cubic-bezier(.1,.9,1,1);
          }
    }

    @media screen and (max-width: 768px){
        border: 0px solid #f9f9f9;
        margin: 0;
        grid-column: span 6;
    }
    @media screen and (max-width: 480px){
        border: 0px solid #f9f9f9;
        padding: 0;
        grid-column: 1 / span 12;
    }
`;



