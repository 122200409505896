import styled from 'styled-components';
import { IoMdArrowRoundDown } from 'react-icons/io';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';

export const HeroContainer = styled(motion.div)`
    /* background: #191919; */
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    position: relative;
    z-index: 1;
    margin-top: -80px;

    :before {
        content: ''; 
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
    }

    @media screen and (min-width: 769px){
        width: 1200px;
        max-width: calc(100% - 128px);
        min-height: 512px;
        margin: 48px auto;
        grid-column-gap: 64px;
        grid-row-gap: 0;
    }

    @media screen and (max-width: 768px){
        padding: 16px;
        max-height: unset;
        grid-template-rows: repeat(12,minmax(0,1fr));
    }

`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* box-shadow: 0 8px 16px rgba(0,0,0,0.15); */
    border-radius: 2px;

    @media screen and (min-width: 769px){
        grid-column: 5 / span 8;
        grid-row: 1 / span 10;
    }

    @media screen and (max-width: 768px){
        margin: 88px auto 0;
        grid-column: 1 / span 12;
        grid-row: 1 / span 4;
        grid-column-gap: 8px;
        grid-row-gap: 24px;
        
    }
`;

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    /* background: #000; */
`;

export const HeroContent = styled(motion.div)`
/*     display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr)); */
    position: relative;
    z-index: 3;
    width: 100%;
    mix-blend-mode: difference;
    color: #191919;

    @media screen and (min-width: 769px){
        max-width: 1200px;
        /* margin: 256px auto; */
        grid-column-gap: 64px;
        grid-row-gap: 32px;
        grid-row: 1;
        grid-column: 1 / span 4;
    }

    @media screen and (max-width: 768px){
        margin: 0 auto 96px;
        grid-column-gap: 8px;
        grid-row-gap: 24px;
        grid-column: 1 / span 12;
        grid-row: 7 / span 6;
    }
    
`;

export const HeroH1 = styled.h1`
    z-index: 3;
    position: relative;
    display: grid;
    line-height: 1.2;
    font-weight: 400;

    @media screen and (min-width: 769px){
        /* grid-column: 1 / span 6; */
        font-size: 32px;
        margin-bottom: 16px;
    }

    @media screen and (max-width: 768px){
        font-size: 24px;
        margin-bottom: 16px;
        /* grid-column: 2 / span 10; */
    }

    :before {
        content: '${props => props.content}';
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        color: #c9c9c9;
        -webkit-letter-spacing: 0.1rem;
        -moz-letter-spacing: 0.1rem;
        -ms-letter-spacing: 0.1rem;
        letter-spacing: 0.1rem;
        position: relative;
        display: block;
        padding-bottom: 8px;
    }
`;

export const HeroP = styled.p`
    font-size: 14px;
    line-height: 2;
    color: #696969;

    @media screen and (min-width: 769px){
        grid-column: 1 / span 6;
        margin-bottom: 32px;
    }

    @media screen and (max-width: 768px){
        font-size: 14px;
        margin-bottom: 24px;
       /*  grid-row: 2;
        grid-column: 2 / span 10; */
    }

`;

export const HeroLink = styled(Link)`
    color: #191919;
    text-decoration: underline;
    cursor: pointer;
`;

export const HeroBtnWrapper = styled.div`

    @media screen and (min-width: 769px){
        /* grid-column: 1 / span 6; */
    }

    @media screen and (max-width: 768px){
        /* grid-row: 3;
        grid-column: 2 / span 10; */
    }
    
`;

export const ArrowDown = styled(IoMdArrowRoundDown)`
    margin-left: 8px;
    font-size: 12px;
`;

