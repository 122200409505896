import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

    *{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Roboto, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html{
        overflow-x: hidden;
        background-color: #f9f9f9;
    }

    a{
        text-decoration: none;
    }
    .loader{
        font-size: 8px;
        font-weight: 900;
        color: #ddd;
        display: block;
        position: absolute;
        text-transform: uppercase;
        letter-spacing: 1em;
        left: 50%;
        top: 50%;
        transform: translate(-20%, -50%);
    }

    p.about{
        margin-bottom: 8px;
    }

    a.about{
        color: #111;
        text-decoration: underline;
        font-weight: 700;
    }
      
`;