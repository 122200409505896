import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export const SidebarContainer = styled(motion.aside)`
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    top: 0;
    right: 0;
    transition: all 0.3s;
    transition-timing-function: cubic-bezier(.2,.8,1,1);
    visibility : ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    background-color: ${({ isOpen }) => (isOpen ? '#000000' : 'transparent')};
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0%')};

`;

export const SidebarWrapper = styled.div`
`;

export const SidebarMenu = styled.ul`

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    @media screen and (min-width: 769px){
        max-width: 1200px;
        margin: 0 auto;
    }

    @media screen and (max-width: 768px){
    }
`;

export const SidebarBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: ${props => `url(${props.background})`};
    opacity: 0;
`;

export const SidebarLink = styled(motion.custom(Link))`
    text-decoration: none;
    list-style: none;
    transition: all 0.3s;
    transition-timing-function: cubic-bezier(.2,.8,1,1);
    cursor: pointer;
    padding: 32px 0;
    margin-bottom: 16px;
    z-index: 99;

    &:before {
        content: '${props => props.content}';
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        color: #393939;
        letter-spacing: 0.1rem;
        position: relative;
        display: inline-block;
        vertical-align: text-top;
        margin-right: 4px;
    }

   /*  &:hover {
        ${SidebarBackground} {
            opacity: 1;
        }
    } */

    @media screen and (min-width: 769px){
        width: 33.33%;
        padding: 24px;
    }

    @media screen and (max-width: 768px){
        padding: 8px 16px;
        margin-bottom: 16px;
        width: 50%;
    }
`;

export const SidebarHashLink = styled(motion.custom(HashLink))`
    text-decoration: none;
    list-style: none;
    transition: all 0.3s;
    transition-timing-function: cubic-bezier(.2,.8,1,1);
    cursor: pointer;
    padding: 32px 0;
    margin-bottom: 16px;
    z-index: 99;

    &:before {
        content: '${props => props.content}';
        font-size: 8px;
        font-weight: 400;
        text-transform: uppercase;
        color: #393939;
        letter-spacing: 0.1rem;
        position: relative;
        display: inline-block;
        vertical-align: text-top;
        margin-right: 4px;
    }

   /*  &:hover {
        ${SidebarBackground} {
            opacity: 1;
        }
    } */

    @media screen and (min-width: 769px){
        width: 33.33%;
        padding: 24px;
    }

    @media screen and (max-width: 768px){
        padding: 8px 16px;
        margin-bottom: 16px;
        width: 50%;
    }
`;

export const SidebarLinkTopline = styled.p`
   font-size: 8px;
   font-weight: 800;
   color: #696969;
   letter-spacing: 0.1rem;
   border-top: 1px solid #292929;
   padding: 8px 0;
   transition: all 3s;
   transition-timing-function: cubic-bezier(.2,.8,1,1);
   opacity: ${({ isOpen }) => (isOpen ? '100%' : '0%')};
`;

export const SidebarLinkHeadline = styled.p`
   font-weight: 300;
   color: #f9f9f9;
   transition: all 3s;
   transition-timing-function: cubic-bezier(.2,.8,1,1);
   opacity: ${({ isOpen }) => (isOpen ? '100%' : '0%')};

    @media screen and (min-width: 769px){
        font-size: 24px;
        padding-bottom: 24px;
    }

   @media screen and (max-width: 768px){
       font-size: 16px;
       padding-bottom: 16px;
   }

   @media screen and (max-width: 480px){
    font-size: 12px;
    padding-bottom: 12px;
}
`;