import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ServicesContainer = styled.div`
    width: 100%;
    background: #090909;
    padding: 128px 0;
    color: #f9f9f9;
`;

export const ServicesWrapper = styled(motion.div)`
    display: grid;
    grid-gap: 64px;
    margin: 0 auto;
    grid-template-columns: repeat(12, minmax(0,1fr));

    @media screen and (min-width: 769px){
        width: 1200px;
        max-width: calc(100% - 128px);
    }

    @media screen and (max-width: 768px){
        /* width: calc(100% - 32px); */
        padding: 0 16px;
        grid-column-gap: 0;
        grid-row-gap: 64px;
    }
`;

export const ServicesIcon = styled(motion.div)`
    width: 100%;
    height: 256px;
    margin-bottom: 16px;
    border-radius: 2px;
    background-color: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: 0 8px 16px rgba(0,0,0,1);
    transition: transform 0.6s, box-shadow 0.9s;
    transition-timing-function: cubic-bezier(.2,.8,1,1);
`;

export const ServicesCard = styled(motion.div)`

@media screen and (min-width: 769px){
    grid-column: span 4;
}

@media screen and (max-width: 768px){
    grid-column: span 12;
}

@media screen and (max-width: 480px){
    grid-column: 1 / span 12;
}

/*  box-shadow: 0 16px 24px rgba(0,0,0,0.95);
    transition: transform 0.6s, box-shadow 0.9s;
    transition-timing-function: cubic-bezier(.2,.8,1,1);
    background-color: #191919;
    border-radius: 16px;
    padding: 32px;
    grid-column: 3fr;

    &:hover{
        transform: scale(1.05);
        box-shadow: 0 4px 16px rgba(0,0,0,0.75);
        transition: transform 0.3s, box-shadow 0.6s;
        transition-timing-function: cubic-bezier(.2,.8,1,1);
        cursor: pointer; 

        ${ServicesIcon} {
            transform: scale(1.035);
            box-shadow: 0 4px 4px rgba(0,0,0,0.75);
            transition: transform 0.6s, box-shadow 0.9s;
            transition-timing-function: cubic-bezier(.2,.8,1,1);
          }
    }*/
`;

export const ServicesH1 = styled.h1`
    font-size: 32px;
    color: #f1f1f1;
    margin: 0 auto;
    font-weight: 400;

    @media screen and (min-width: 769px){
        width: 1200px;
        max-width: calc(100% - 128px);
        padding: 0 0 48px;
    }
    @media screen and (max-width: 768px){
        width: calc(100% - 32px);
        padding: 0 0 32px;
    }
`;

export const ServicesH2 = styled.h2`
    font-size: 16px;
    font-weight: 500;
    margin: 24px 0 16px;
    /* border-top: 1px solid #292929; */
`;

export const ServicesP = styled.p`
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 24px;
    line-height: 2;
    color: #c9c9c9;
`;

export const BackgroundWrap = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    /* background-color: #000000; */
`;

export const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(8px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
`;

