import React, {useState, useEffect} from 'react'
import { AnimatePresence } from 'framer-motion';
import { GlobalStyle } from './GlobalStyles'
import { Switch, Route, useLocation } from 'react-router-dom';

import Home from './pages';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Project from './pages/project';
import Footer from './components/Footer'
import { projectData } from './components/InfoSection/Data';

function App() {

  let location = useLocation();

  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    const images = projectData.map(function(project) {
      return [project.img, project.img2];
    });

    const videos = projectData.map(function(project) {
      return project.video;
    });

    console.log(videos);
    console.log(images);

    cacheImages(images, videos);

    console.log(cacheImages);

  }, []);

  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((imageSrc, videoSrc) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();
        const video = document.createElement('video');

        img.src = imageSrc;
        img.onload = resolve();
        img.onerror = reject();

        video.src = videoSrc;
        video.onload = resolve();
        video.onerror = reject();
      });
    });

    await Promise.all(promises);

    setIsloading(false);

  };

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
      setIsOpen(!isOpen)
  };

  return (
    <>
    <GlobalStyle />
    {isLoading
    ?
    <div className="loader">Loading</div>
    :
    <>
      <Navbar isOpen={isOpen} toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/" component={ Home } />
          <Route exact path="/project/:projectId" component={ Project } />
        </Switch>
      </AnimatePresence>
      <Footer />
      </>}
      </>
  );
}

export default App;