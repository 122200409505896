import React, {useState} from 'react'
import {SidebarContainer,
        SidebarWrapper,
        SidebarMenu,
        SidebarLink,
        SidebarHashLink,
        SidebarLinkHeadline,
} from './SidebarElements' 
import { projectData } from '../InfoSection/Data'
import { BackgroundNoise } from '../BackgroundNoise'


const animation = {
    open: {
        rotate: 135
    },
    closed: {
        rotate: 0
    }
}

const Sidebar = ({ isOpen, toggle }) => {

    const [hover, setHover] = useState(false)
    
    const onHover = () => {
        setHover(!hover)
    }

    return (
        
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <BackgroundNoise />
            <SidebarWrapper >
                <SidebarMenu>
                {projectData.map((project, index) => {
                    return (
                        <SidebarLink 
                            to={`/project/${index + 1}`}
                            onClick={toggle}
                            isOpen={isOpen}
                            key={index} 
                            replace
                            initial={true}
                            animate={isOpen ? "open" : "closed"}
                            variations={animation}
                            onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            content={'PROJECT 0'+`${index+1}`}>
                            <SidebarLinkHeadline isOpen={isOpen}>{project.headLine}</SidebarLinkHeadline>
                        </SidebarLink>
                   )})}
                   {/* <SidebarBackground background={hover ? project.img : 'none' }/> */}
                   <SidebarLink 
                            to="/"
                            onClick={toggle}
                            isOpen={isOpen}
                            smooth
                            replace
                            initial={true}
                            animate={isOpen ? "open" : "closed"}
                            variations={animation}
                            onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            content='Link'>
                            <SidebarLinkHeadline isOpen={isOpen}>Home</SidebarLinkHeadline>
                        </SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    );
};

export default Sidebar;
