import React from 'react';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import Services from '../components/Services';
import About from '../components/About';
import { projectData } from '../components/InfoSection/Data';
import { motion } from 'framer-motion';
import ScrollToTop from '../components/ScrollToTop';

const Home = () => {

  return (
        <>
        <ScrollToTop/>
        <motion.div initial={{ opacity: 0, y: 10}} animate={{ opacity: 1 , y: 0}} exit={{ opacity: 0, y: -10}} transition={{ duration: 1 , staggerChildren: 0.3}}>
          <HeroSection/>
          <InfoSection data={projectData}/>
          <Services />
          <About />
          </motion.div>
        </>
    )
}

export default Home;
