import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { MdArrowForward, MdArrowBack } from 'react-icons/md';

export const ProjectWrapper = styled(motion.div)`

    display: grid;
    position: relative;
    grid-template-columns: repeat(12, minmax(0, 1fr));

    @media screen and (min-width: 769px) {
        grid-gap: 64px;
        margin: 64px auto 96px;
        width: 1200px;
        max-width: calc(100% - 128px);
    }

    @media screen and (max-width: 768px) {
        grid-row-gap: 33px;
        padding: 0 16px;
        margin: 32px 0 96px;
        width: 100%;
    }
`;

export const ProjectBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
`;

export const ProjectNumber = styled.div`

`;

export const ProjectHeadline = styled.div`
    font-weight: 400;
    line-height: 1;
    /* border-top: 1px solid #e9e9e9; */
    grid-column: span 6;

    @media screen and (min-width: 769px) {
        font-size: 16px;
        border-top: none;
        padding-top: 0;
    }

    @media screen and (max-width: 768px) {
        font-size: 14px;
    }

    &:before {
        content: '${props => props.content}';
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        color: #c9c9c9;
        letter-spacing: 0.1rem;
        position: relative;
        display: block;

        @media screen and (min-width: 769px) {
            padding: 0 0 8px 0;
        }
    
        @media screen and (max-width: 768px) {
            padding: 0 0 8px 0;
        }
    }

`;

export const ProjectCopy = styled.div`

    font-size: 14px;
    color: #444;
    line-height: 2;
    /* border-top: 1px solid #e9e9e9; */

    @media screen and (min-width: 769px) {
        grid-column: span 4;
        padding: 0;
    }

    @media screen and (max-width: 768px) {
        grid-column: span 12;
        padding: 24px 0;
    }

    &:before {
        content: '${props => props.content}';
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        color: #c9c9c9;
        letter-spacing: 0.1rem;
        position: relative;
        display: block;
        padding-bottom: 8px;
    }
`;

export const VideoContainer = styled.div`
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* box-shadow: 0 8px 16px rgba(0,0,0,0.15); */
    border-radius: 2px;

    @media screen and (min-width: 769px){
        grid-column: span 12;
        grid-row: 2;
    }

    @media screen and (max-width: 768px){
        grid-column: 1 / span 12;
        grid-row: 2;
    }
`;

export const Video = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    /* background: #000; */

/*     @media screen and (min-width: 769px) {
        width: 100%;
        margin-left: 0;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        margin-left: -10%;
    } */
`;

export const VideoSmall = styled(motion.video)`
    width: 100%;
    border-radius: 2px;

    &:after{
        width: 100%;
        height: 100%;
        content: close-quote;
        filter: url(#noise) opacity(0.2);
        position: absolute;
        top: 0;
        left: 0;
        display: table;
    }

    @media screen and (min-width: 769px) {
        grid-column: ${props => props.gridColumn};
    }

    @media screen and (max-width: 768px) {
        grid-column: span 12;
    }
`;

export const Img = styled(motion.div)`
    width: 100%;
    border-radius: 2px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (min-width: 769px){
        grid-column: ${props => props.gridColumn};
        height: 512px;
    }

    @media screen and (max-width: 768px) {
        grid-column: 1 / span 12;
        height: 256px;
    }
`;

export const ButtonContainer = styled.div`
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 2px;
    overflow: hidden;

    :after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.25);
        display: table;
        content: close-quote;
        transition: all 2s;
        transition-timing-function: cubic-bezier(.1,.9,1,1);
    }

    @media screen and (min-width: 769px){
        height: 352px;
        margin: 0 0 8px;
        
    }

    @media screen and (max-width: 768px){
        height: 196px;
        margin: 8px 0 4px;
    }
`;

export const ButtonTitle = styled.h1`
    margin: 128px 0 0;
    font-size: 24px;
    grid-column: span 12;
    font-weight: 400;
    /* border-top: 1px solid #e9e9e9; */

    @media screen and (min-width: 769px){
        padding: 32px 0 8px;
        grid-row: 6;
    }

    @media screen and (max-width: 768px){
        padding: 24px 0 16px;
    }  
`;

export const Arrow = styled.i`
    position: absolute;
    background-image: url(${props => props.backgroundUrl};);
    background-size: contain;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    mix-blend-mode: soft-light;
    color: rgba(255,255,255,0.6);
    transition: all 2s;
    transition-timing-function: cubic-bezier(.1,.9,1,1);

    @media screen and (min-width: 769px){
        font-size: 64px;
    }

    @media screen and (max-width: 768px){
        font-size: 32px;
    }
`;

export const ArrowNext = styled(MdArrowForward)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    mix-blend-mode: soft-light;
    color: rgba(255,255,255,0.1);
    transition: all 2s;
    transition-timing-function: cubic-bezier(.1,.9,1,1);

    @media screen and (min-width: 769px){
        font-size: 128px;
    }

    @media screen and (max-width: 768px){
        font-size: 64px;
    }
`;

export const ArrowPrev = styled(MdArrowBack)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    mix-blend-mode: soft-light;
    color: rgba(255,255,255,0.1);
    transition: all 2s;
    transition-timing-function: cubic-bezier(.1,.9,1,1);

    @media screen and (min-width: 769px){
        font-size: 128px;
    }

    @media screen and (max-width: 768px){
        font-size: 64px;
    }
`;

export const ButtonWrap = styled(motion.custom(Link))`
    color: #191919;
    width: 100%;
    border-radius: 2px;
    grid-column: span 6;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 4px;
    overflow: hidden;

    &:before {
        content: '${props => props.before}';
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        color: #c9c9c9;
        letter-spacing: 0.1rem;
        position: relative;
        display: block;

        @media screen and (min-width: 769px){
            padding-bottom: 16px;
        }
    
        @media screen and (max-width: 768px){
            padding-bottom: 0px;
        }
    }

    &:after {
        content: '${props => props.after}';
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        color: #c9c9c9;
        letter-spacing: 0.1rem;
        position: relative;
        display: block;
        padding-bottom: 16px;
    }

    &:hover {
        ${ButtonContainer} {
            :after{
                background-color: rgba(0,0,0,0);
                transition: all 2s;
                transition-timing-function: cubic-bezier(.1,.9,1,1);
            }
        }
        ${ArrowNext} {
            color: rgba(0,0,0,0.1);
            transition: all 2s;
            transition-timing-function: cubic-bezier(.1,.9,1,1);
        }
        ${ArrowPrev} {
            color: rgba(0,0,0,0.1);
            transition: all 2s;
            transition-timing-function: cubic-bezier(.1,.9,1,1);
        }
    }

   @media screen and (min-width: 769px){
        grid-column: ${props => props.gridColumn};
        grid-row: 7;
    }

    @media screen and (max-width: 768px){
        grid-column: span 12;
        margin-bottom: 0px;
    }
`;