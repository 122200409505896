import React from 'react';
import { MdArrowForward, MdArrowBack } from 'react-icons/md';
import { BackgroundNoise } from '../BackgroundNoise';
import {
    ProjectWrapper,
    ProjectHeadline,
    ProjectCopy,
    VideoContainer,
    Video,
    VideoSmall,
    Img,
    ButtonWrap,
    ButtonContainer,
    ButtonTitle,
    ArrowNext,
    ArrowPrev,
    Arrow
} from './ProjectElements';

const ProjectSection = ({
    total,
    topLine,
    topLabel,
    headLine,
    brief,
    approach,
    solution,
    img2,
    img3,
    img4,
    video,
    video2,
    video3,
    prev,
    next,
    prevCopy,
    nextCopy,
    prevImage,
    nextImage
}) => {
    return (

        
        
        <>
        <ProjectWrapper
            initial={{ opacity: 0, y: 8 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -8 }}
            transition={{ duration: 1, staggerChildren: 0.5 }}
        >
            {/* <ProjectHeadline content={'Project ' + `${topLine}` + ' of ' + '0' + `${total}`}>{headLine}</ProjectHeadline> */}
            <ProjectHeadline content={'Project ' + `${topLine}`} /* content2={'/' + `${total}`} */>{headLine}</ProjectHeadline>
            <ProjectHeadline content="Contribution">{topLabel}</ProjectHeadline>
            <VideoContainer>
                <Video muted autoPlay loop playsInline gridColumn="span 12" src={video} type='video/mp4'/>
                <BackgroundNoise />
            </VideoContainer>
            <ProjectCopy content="About"><div dangerouslySetInnerHTML={{__html: brief}}/></ProjectCopy>
            {/* {approach ? (
                <ProjectCopy content="Approach">{approach}</ProjectCopy>
            ) :(
                <div />
            )} */}
            <Img style={{backgroundImage: `url(${img4})`}} gridColumn="span 8" />
            {/* <ProjectCopy content="02 Approach">{approach}</ProjectCopy>
            <ProjectCopy content="03 Solution">{solution}</ProjectCopy> */}
            {video2 ? (
                <VideoSmall muted autoPlay loop playsInline gridColumn="1 / span 8" src={video2} type='video/mp4'/>
                    ) : (
                <Img style={{backgroundImage: `url(${img2})`}} gridColumn="1 / span 8" />
            )}
            {video3 ? (
                <VideoSmall muted autoPlay loop playsInline gridColumn="5 / span 8" src={video3} type='video/mp4'/>
                    ) : (
                <Img style={{backgroundImage: `url(${img3})`}} gridColumn="5 / span 8" />
            )}
            <ButtonTitle>More projects</ButtonTitle>
            <ButtonWrap to={`/project/${next}`} gridColumn="5 / span 8" before={'Next ' + '(0' + `${next}` + ')'} /* after={nextCopy} */>
            <ButtonContainer style={{backgroundImage: `url(${nextImage})`}}>
             <ArrowNext />
             {/* <Arrow backgroundUrl='${MdArrowForward}' /> */}
             {/* <MdArrowForward className='Arrow'/> */}
            </ButtonContainer>
            <ProjectHeadline>{nextCopy}</ProjectHeadline>
            </ButtonWrap>
            <ButtonWrap to={`/project/${prev}`} gridColumn="1 / span 4" before={'Previous ' + '(0' + `${prev}` + ')'}>
            <ButtonContainer style={{backgroundImage: `url(${prevImage})`}}>
            <ArrowPrev />
            </ButtonContainer>
            <ProjectHeadline>{prevCopy}</ProjectHeadline>
            </ButtonWrap>
        </ProjectWrapper>
        </>
    )
}

export default ProjectSection;