import React from 'react';
import { BackgroundNoise } from '../BackgroundNoise';
import { GridWrapper } from '../GridWrapper';
import { AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Title,
    TopLabel,
    Heading,
    Subtitle,
    ImgWrap,
    Img,
    Video
} from './InfoElements';
import { projectData } from './Data';

const InfoSection = ({ data }) => {

    const [ref, inView] = useInView({
        triggerOnce: false,
        rootMargin: '300px 0px',
      });


    const fadeIn = {
        visible: {
            opacity: 1,
        },
        hidden: {
            opacity: 0.95,
        }
    }

    const transition = { duration: 2, ease: [0.43, 0.13, 0.23, 0.96] };

    return (
        <>
        <AnimatePresence>
        <GridWrapper id="infosection"> 
        <Title initial={{ opacity: 0, y: 20}} animate={{ opacity: 1 , y: 0}} exit={{ opacity: 0, y: -20}} content={(projectData.length)}>Projects</Title>
        {data.map((project, index) => {
            return (
                <InfoContainer id={project.id} to={`/project/${index + 1}`} replace key={index} initial={{ opacity: 0, y: 10}} animate={{ opacity: 1 , y: 0}} exit={{ opacity: 0, y: -10}} transition={{ duration: 1, staggerChildren: 0.5 }}>
                    <InfoWrapper ref={ref} initial={false} animate={inView ? "visible" : "hidden"} variants={fadeIn} transition={transition}>
                        <InfoRow>
                            <TopLabel textAlign="left">0{index + 1}</TopLabel>
                            <TopLabel textAlign="right">{project.topLabel}</TopLabel>
                            <ImgWrap>
                            {project.startVideo ? (
                                <Video muted autoPlay loop playsInline src={project.startVideo} type='video/mp4'/>
                            ) : (
                                <Img style={{backgroundImage: `url(${project.img})`}} />
                            )}
                            <BackgroundNoise />
                            </ImgWrap>
                            <Heading>{project.headLine}</Heading>
                            <Subtitle><div dangerouslySetInnerHTML={{__html: project.brief}}/></Subtitle>
                        </InfoRow>
                    </InfoWrapper>
                </InfoContainer> 
            )
        })}
        </GridWrapper>
        </AnimatePresence>
        </>
    )
}

export default InfoSection;