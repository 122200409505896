import styled from 'styled-components';
import { motion } from 'framer-motion';

export const AboutContainer = styled(motion.div)`
    font-size: 14px;
    padding: 96px 0;
    background: linear-gradient(180deg, rgba(249,249,249,1) 0%, rgba(255,255,255,1) 100%);

    @media screen and (max-width: 768px){
        padding: 96px 0;
    }
`;

export const AboutWrapper = styled(motion.div)`

    display: grid;
    position: relative;
    margin: 48px auto;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-gap: 32px;

    @media screen and (min-width: 769px) {
        width: 1200px;
        max-width: calc(100% - 128px);
    }

    @media screen and (max-width: 768px) {
        padding: 0 16px;
        grid-row-gap: 40px;
        grid-column-gap: 0;
    }
`;

export const AboutH1 = styled.h1`
    font-size: 32px;
    color: #191919;
    margin: 0 auto;
    font-weight: 400;

    @media screen and (min-width: 769px){
        width: 1200px;
        max-width: calc(100% - 128px);
        padding: 0;
    }

    @media screen and (max-width: 768px){
        padding: 0 16px 0;
    }
`;

export const AboutImage = styled(motion.div)`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 2px;

    &:before {
        content: '${props => props.content}';
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        color: #191919;
        letter-spacing: 0.1rem;
        position: relative;
        display: block;
        padding: 24px;
        text-align: right;
    }

    @media screen and (min-width: 769px){
        grid-column: 7 / span 6;
        grid-row: 1 / span 3;
    }

    @media screen and (max-width: 768px){
        height: 290px;
        grid-column: 1 / span 12;
        grid-row: 2;
    }
`;

export const AboutTop = styled(motion.div)`

    @media screen and (min-width: 769px){
        grid-column: span 2;
        font-size: 14px;
    }

    @media screen and (max-width: 768px){
        grid-column: span 4;
        font-size: 14px;
        padding-right: 8px;
    }

    &:before {
        content: '${props => props.content}';
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        color: #c9c9c9;
        letter-spacing: 0.1rem;
        position: relative;
        display: block;
        padding-bottom: 8px;
    }
`;

export const AboutSkills = styled(motion.div)`
    /* border-top: 1px solid #e9e9e9; */
    padding-top: 8px;

    @media screen and (min-width: 769px){
        grid-column: 7 / span 6;
    }
    

    @media screen and (max-width: 768px){
        grid-column: 1 / span 12;
    }

    &:before {
        content: '${props => props.content}';
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        color: #c9c9c9;
        letter-spacing: 0.1rem;
        position: relative;
        display: block;
        padding-bottom: 8px;
    }

    & svg {
        margin: 8px 8px 0px 0;
        display: inline-block;
        border: 2px solid #ffffff;
        border-radius: 8px;
        text-align: center;
        font-size: 40px;
        padding: 10px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        background-color: #ffffff;
    }
`;

export const AboutCopy = styled(motion.div)`
    padding-top: 8px;
    color: #696969;
    line-height: 2;

    @media screen and (min-width: 769px){
        grid-column: 1 / span 6;
        grid-row: 4 / span 2;
    }

    @media screen and (max-width: 768px){
        grid-column: 1 / span 12;
        grid-row: 3 / span 2;
    }

    &:before {
        content: '${props => props.content}';
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        color: #c9c9c9;
        letter-spacing: 0.1rem;
        position: relative;
        display: block;
        padding-bottom: 8px;
    }
`;

export const AboutWork = styled(motion.div)`
    /* border-top: 1px solid #e9e9e9; */
    padding-top: 8px;

    @media screen and (min-width: 769px){
        grid-column: ${props => props.gridColumn};
    }

    @media screen and (max-width: 768px){
        grid-column: 1 / span 12;
    }

    &:before {
        content: '${props => props.content}';
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        color: #c9c9c9;
        letter-spacing: 0.1rem;
        position: relative;
        display: block;
        padding-bottom: 16px;
    }
`;

export const AboutPlace = styled.div`
        font-size: 14px;
        margin-bottom: 16px;

        &:after {
            content: '${props => props.content}';
            font-size: 8px;
            font-weight: 600;
            color: #d9d9d9;
            position: relative;
            display: block;
            padding-top: 8px;
        }
`