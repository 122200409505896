import React from 'react';
import { useInView } from 'react-intersection-observer';
import Icon1 from '../../images/experiments/piggy2.gif';
import Icon2 from '../../images/experiments/portrait.gif';
import Icon3 from '../../images/experiments/liked.gif';
import {
    ServicesContainer,
    ServicesH1,
    ServicesH2,
    ServicesWrapper,
    ServicesCard,
    ServicesIcon,
    ServicesP
} from './ServicesElements';

const Services = () => {

    const [ref, inView] = useInView({
        triggerOnce: false,
        rootMargin: '0px 0px',
      });


    const fadeIn = {
        visible: {
            opacity: 1,
            y: 0
        },
        hidden: {
            opacity: 0,
            y: 16
        }
    }

    const transitionParent = { duration: 2, ease: [0.43, 0.13, 0.23, 0.96], staggerChildren: 10 };

    return (
        <ServicesContainer id="experiments">
            <ServicesH1>Experiments</ServicesH1>
            <ServicesWrapper ref={ref} initial={false} animate={inView ? "visible" : "hidden"} variants={fadeIn} transition={transitionParent}>
                <ServicesCard /* ref={ref} initial={false} animate={inView ? "visible" : "hidden"} variants={fadeIn} */>
                    <ServicesIcon style={{backgroundImage: `url(${Icon1})`}} />
                    <ServicesH2>Piggy bank animation</ServicesH2>
                    <ServicesP>3D animation made in Blender and After Effects.</ServicesP>
                </ServicesCard>
                <ServicesCard /* ref={ref} initial={false} animate={inView ? "visible" : "hidden"} variants={fadeIn} */>
                    <ServicesIcon style={{backgroundImage: `url(${Icon2})`}} />
                    <ServicesH2>Self portrait</ServicesH2>
                    <ServicesP>3D self portrait made in Blender and After Effects.</ServicesP>
                </ServicesCard>
                <ServicesCard /* ref={ref} initial={false} animate={inView ? "visible" : "hidden"} variants={fadeIn} */>
                    <ServicesIcon style={{backgroundImage: `url(${Icon3})`}} />
                    <ServicesH2>Golden ratio logo</ServicesH2>
                    <ServicesP>Animated logotype created in Illustrator and After Effects using the golden ratio.</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services;
