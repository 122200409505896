import React from 'react';
import Video from '../../video/hero.mp4';
/* import { ButtonScrollDark } from '../ButtonElements'; */
import { BackgroundNoise } from '../BackgroundNoise';
import {
        HeroContainer, 
        HeroBg,
        VideoBg,
        HeroContent,
        HeroH1,
        HeroP,
        HeroLink
    } from './HeroElements';

const HeroSection = () => {

    return (
        <>
          <HeroContainer >
            <HeroBg>
                <VideoBg muted autoPlay loop playsInline src={Video} type='video/mp4'/>
                <BackgroundNoise />
            </HeroBg>
            <HeroContent initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1}} transition= {{ when: "beforeChildren", staggerChildren: 0.3,}}>
                <HeroH1 content="Welcome">
                    Hi my name is Max and this is my portfolio!
                </HeroH1>
                <HeroP>
                To better understand my fellow developer colleagues I decided to learn how to build a website in React.
                Special thank to Brian Design for his amazing React tutorials and my colleague (and friend) Mike who helped me the many times I got stuck developing this page.
                <br/><br/>
                View my <HeroLink to="infosection" smooth={true} offset={-100}>Projects</HeroLink>, look at my <HeroLink to="experiments" smooth={true}>Experiments</HeroLink> or read more <HeroLink to="about" smooth={true}>About</HeroLink> me, happy scrolling!
                </HeroP>
{/*                 <HeroBtnWrapper>
                    <ButtonScrollDark 
                        to="infosection"
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        smooth={true}
                        duration={300}
                        spy={true}
                        offset={-80}
                    >
                        Scroll down <ArrowDown />
                    </ButtonScrollDark>
                </HeroBtnWrapper> */}
            </HeroContent>
          </HeroContainer>
        </>
    )
}

export default HeroSection
