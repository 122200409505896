export const projectData = [
    {
        topLabel: 'UX / UI',
        headLine: 'Comviq Startpage',
        description: '',
        brief: '<p class="about">User data told me that the third most clicked button (Se alla mobiler / See all devices) was way belove the fold. Now it is easy accessible just above the menu.</p><p class="about">Data also showed we had a lot of misclicks cause the buttons for opening and closing the menu was not placed in the same position. Also moved menu to the bottom like an app making it easier to reach.</p><p class="about">Second and third hero banner are rarley clicked which questions their existence.</p><p class="about">Put the three product categories (Devices, Subscriptions and Broadband) in tabs so they can be viewed without scrolling.</p>',
        approach: '',
        solution: '',
        video: require('../../images/comviq/startpage/startpage.mp4'),
        img: require('../../images/comviq/startpage/startpage1.png'),
        img1: require('../../images/comviq/startpage/startpage1.png'),
        img2: require('../../images/comviq/startpage/startpage1.png'),
        img3: require('../../images/comviq/startpage/startpage2.png'),
        img4: require('../../images/comviq/startpage/process.png'),
    },
    
    {
        topLabel: 'UX / UI',
        headLine: 'Hyper Island Application',
        description: 'Redesign of a recipe page at ICA',
        brief: '<p class="about">Object was to improve the ux of any digital product and I chose to redesign a recipe page on ICAs website.</p><p class="about">Started by using the original design and followed a new recipe, constantly taking notes on good things and what could be improved.</p><p class="about">Then began to look for inspiration. A lot of foodsites but also educational, step-by-step, tutorial sites within other areas.</p><p class="about">Moved on to Figma to explore and wireframe with constant feedback from other UX-designers.</p><p class="about">Applied visuals and created animations and lastly put everything in After Effects to make the presentation video you can watch above.</p>',
        approach: 'Used a recipe from ICA.se and took notes of all things I wanted to change.',
        solution: 'A more streamlined page with way less scrolling and better looking visuals.',
        video: require('../../video/hyper.mp4'),
        img: require('../../images/hyper/hyper.png'),
        video2: require('../../images/hyper/hyper1.mp4'),
        video3: require('../../images/hyper/hyper2.mp4'),
        img3: require('../../images/hyper/hyper1.mp4'),
        img4: require('../../images/hyper/process.png'),
    },

    {
        topLabel: 'UX / UI / 3D',
        headLine: 'Comviq Black Friday',
        description: 'Black Friday Campaign page for Comviq designed in 2020.',
        brief: '<p class="about">Campaign page for Comviq with generic products to build interest witout revealing the accutal offers.</p><p class="about">Modelled a generic phone, SIM-card and router in Blender and combined them for the background.</p><p class="about">During the campaign we constantly observed user data to make small adjustments to product order and copy which boosted sales.</p>',
        approach: 'Modelled a generic phone, SIM-card and router in Blender and combined them for the background.',
        solution: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        startVideo: require('../../images/comviq/blackfriday/blackfriday.mp4'),
        video: require('../../images/comviq/blackfriday/comviqBlackFriday2.mp4'),
        img: require('../../images/comviq/blackfriday/blackfriday.png'),
        img1: require('../../images/comviq/blackfriday/blackfriday.png'),
        video2: require('../../images/comviq/blackfriday/blackfriday.mp4'),
        img3: require('../../images/comviq/blackfriday/blackfriday.png'),
        img4: require('../../images/comviq/blackfriday/blackfriday1.png'),
    },
    
    {
        topLabel: 'UX / UI',
        headLine: 'Tele2 Family Online',
        description: 'Online sales flow for Tele2 Family.',
        brief: '<p class="about">An online sales flow for Tele2 Family subscriptions that has previously only been available to order in stores or by phone.</p><p class="about">Not having it online put a lot of pressure on customer service and the Tele2 stores</p><p class="about">During the design process I built a prototype and did a lot of guerilla testing in the office and on friends and family that resulted in design and copy changes.</p><p class="about">Now the online family purchase product accounts for a third of all online sale of subscriptions.</p>',
        approach: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        solution: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        video: require('../../images/tele2/family/family.mp4'),
        img: require('../../images/tele2/family/family.jpg'),
        img1: require('../../images/tele2/family/family.jpg'),
        img2: require('../../images/tele2/family/family.jpg'),
        img3: require('../../images/tele2/family/familyDesktop.png'),
        img4: require('../../images/tele2/family/overview.png'),
    },

    {
        topLabel: '3D',
        headLine: 'Comviq E-sim',
        description: 'Visuals to promote the use of E-sim.',
        brief: '<p class="about">Key visual for Comviq E-sim to encourage customers to choose E-SIM istead of regular SIM.</p><p class="about">Modelled in Blender and animated in After Effects.</p>',
        approach: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        solution: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        video: require('../../video/esim.mp4'),
        img: require('../../images/comviq/esim/esim.png'),
        img1: require('../../images/comviq/esim/esim.png'),
        img2: require('../../images/comviq/esim/esim2.png'),
        img3: require('../../images/comviq/esim/esim3.png'),
        img4: require('../../images/comviq/esim/esim4.png'),
    },
    
    {
        topLabel: 'UX / UI / 3D',
        headLine: 'Comhem Black Friday',
        description: 'Black Friday Campaign page for Comviq designed in 2019.',
        brief: '<p class="about">Campaign page for Comhem Black Friday, made a night version of the key visual and did some scroll animations explorations.</p><p class="about">Campaign page was a success and outperformed the previous one.</p>',
        approach: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        solution: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        video: require('../../video/comhemBlackFriday.mp4'),
        img: require('../../images/comhem/blackfriday/dragon.png'),
        img1: require('../../images/comhem/blackfriday/dragon.png'),
        img2: require('../../images/comhem/blackfriday/dragon2.png'),
        img3: require('../../images/comhem/blackfriday/dragon.png'),
        img4: require('../../images/comhem/blackfriday/blackfriday2.png'),
    },

    {
        topLabel: 'UX / UI / Code',
        headLine: 'Comviq Surf Game',
        description: 'Game developed and design to promote Comviqs presence at summer festivals. At first you could only control the wave by draging it with your finger but after user testing it on young people I added the possibility to control the wave by tilting the phone using the gyro. The game and contest was a hit and resulted in thousands of competitors.',
        brief: '<p class="about">Game developed and design to promote Comviqs presence at summer festivals.</p><p class="about">At first you could only control the wave by draging it with your finger but after user testing it on the target group I added the possibility to control the wave by tilting the phone using the gyro.</p><p class="about">The game and contest was a hit and resulted in thousands of competitors.</p><p class="about">Click <a class="about" href="https://www.maxflick.se/_BACKUP/game/">here</a> if you want to play!</p>',
        approach: 'At first you could only control the wave by draging it with your finger but after user testing it on young people I added the possibility to control the wave by tilting the phone using the gyro. The game and contest was a hit and resulted in thousands of competitors.',
        solution: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        video: require('../../video/storsurf.mp4'),
        video2: require('../../video/heart.mp4'),
        video3: require('../../video/coin.mp4'),
        img: require('../../images/comviq/storsurf/storsurf.png'),
        img1: require('../../images/comviq/storsurf/storsurf.png'),
        img2: require('../../images/comviq/storsurf/storsurf.png'),
        img3: require('../../images/comviq/storsurf/storsurf.png'),
        img4: require('../../images/comviq/storsurf/storsurf.png'),
    },

   /*  {
        topLabel: 'UX / UI',
        headLine: 'Comhem Super Days',
        description: 'Campaign page template designed for Comhem.',
        brief: 'Campaign page template designed for Comhem to make out monthly offers more visually appealing.',
        approach: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        solution: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        video: require('../../video/superdays.mp4'),
        img: require('../../images/comhem/superdays/superdays.jpg'),
        img1: require('../../images/comhem/superdays/superdays.jpg'),
        img2: require('../../images/comhem/superdays/superdays.jpg'),
        img3: require('../../images/comhem/superdays/superdays.jpg'),
        img4: require('../../images/comhem/superdays/superdays.jpg'),
        img5: require('../../images/comhem/superdays/superdays.jpg'),
    }, */

    {
        topLabel: '3D',
        headLine: 'Comhem Chat Bot 2',
        description: 'Mascot for the customer service and sales help chat at Comhem.',
        brief: '<p class="about">Mascot that greets the customer before the agent responds in customer service and sales help chat at Comhem.</p><p class="about">I know users can be pretty frustrated by the time they decide to contact customer service or when they can not make a purchase desicion so tried to make the bot as friendly looking as possible.</p><p class="about">Got a lot of inspiration on Pinterest before starting modelling in Blender and animating in After Effects.</p>',
        approach: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        solution: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        video: require('../../video/chatbot.mp4'),
        img: require('../../images/comhem/chatbot/chatbot5.png'),
        img1: require('../../images/comhem/chatbot/chatbot4.png'),
        img2: require('../../images/comhem/chatbot/chatbot4.png'),
        img3: require('../../images/comhem/chatbot/chatbot6.png'),
        img4: require('../../images/comhem/chatbot/chatbot5.png'),
        img5: require('../../images/comhem/chatbot/chatbot5.png'),
    },

    /*  */
];