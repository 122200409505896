import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export const Nav = styled.nav`
    top: 0;
    height: 72px;
    margin-top: 0;
    font-size: 14px;
    position: sticky;
    z-index: 10;
    overflow: hidden;
    backdrop-filter: blur(0px);
    
    transition: all 0.3s;
    transition-timing-function: cubic-bezier(.2,.8,1,1);
    background-color: ${({ isOpen }) => (isOpen ? 'transparent' : 'rgba(249,249,249,0.8)')};
    background-color: ${({ isOpen }) => (isOpen ? 'transparent' : 'transparent')};
    

    @media screen and (min-width: 769px) {
        height: 72px;
    }

    @media screen and (max-width: 768px) {
        height: 64px;
    }
`;


export const NavbarContainer = styled.div`
    /* display: grid; */
    filter: unset;
    height: 100%;
    transition: all 0.3s;
    transition-timing-function: cubic-bezier(.2,.8,1,1);
    margin: 0 auto;
    
    @media screen and (min-width: 769px) {
        width: 1200px;
        max-width: calc(100% - 128px);
    }

    @media screen and (max-width: 768px) {
        width: calc(100% - 32px);
    }
`;

export const NavLogo = styled(Link)`
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.025rem;
    line-height: .9;
    transition: all 0.3s;
    transition-timing-function: cubic-bezier(.2,.8,1,1);
    color: ${({ isOpen }) => (isOpen ? '#f9f9f9' : '#191919')};
    position: relative;
    float: left;

    @media screen and (min-width: 769px) {
        padding: 20px 0;
    }

    @media screen and (max-width: 768px) {
        padding: 16px 0;
    }
`;

export const NavIcon = styled.div`
    display: block;
    font-size: 30px;
    cursor: pointer;
    position: relative;
    float: right;

    @media screen and (min-width: 769px) {
        padding: 20px 0;
    }

    @media screen and (max-width: 768px) {
        padding: 16px 0;
    }
`;

export const NavIconContainer = styled(motion.div)`
    line-height: 0.8;
    transition: all 0.3s;
    transition-timing-function: cubic-bezier(.2,.8,1,1);
    transform: ${({ isOpen }) => (isOpen ? 'rotate(135deg)' : 'rotate(0deg)')};
    color: ${({ isOpen }) => (isOpen ? '#f9f9f9' : '#191919')};
`;