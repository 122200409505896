/* import React, {useState, useEffect} from 'react'; */
import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { AiOutlinePlusCircle, AiFillPlusCircle } from 'react-icons/ai';
import { 
    Nav, 
    NavbarContainer, 
    NavLogo, 
    NavIcon,
    NavIconContainer
} from './NavbarElements'

    const Navbar = ({ isOpen, toggle }) => {

        const toggleHome = () => {
            scroll.scrollToTop();
        };

    return (
    <>
        <Nav isOpen={isOpen}>
            <NavbarContainer isOpen={isOpen}>
                <NavLogo to='/' exact replace isOpen={isOpen} onClick={toggleHome}>
                    Max<br/>Flick
                </NavLogo>
                <NavIcon>
                <NavIconContainer isOpen={isOpen} onClick={toggle}>
                   {isOpen ? <AiFillPlusCircle /> :  <AiOutlinePlusCircle /> }
                </NavIconContainer>
                </NavIcon>
            </NavbarContainer>
        </Nav>
    </>
    )
}

export default Navbar;
