import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export const FooterContainer = styled(motion.footer)`
    background-color: #090909;
    width: 100%;
    padding: 96px 0 256px;
`;

export const FooterWrap = styled.div`
    position: relative;
    width: 100%;
    display: grid;

    @media screen and (min-width: 769px){
        max-width: 1200px;
        margin: 0 auto;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-gap: 64px;
    }

    @media screen and (max-width: 768px){
        padding: 0 16px;
    }
`;

export const FooterContent = styled.div`
    padding-bottom: 32px;
    font-size: 14px;
    color: #999999;
    line-height: 1.8;

    @media screen and (min-width: 769px){
        grid-column: 3 span;
    }

    @media screen and (max-width: 768px){
        grid-column: ${props => props.gridColumn};
    }

    &:before {
        content: '${props => props.content}';
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        color: #393939;
        letter-spacing: 0.1rem;
        position: relative;
        display: block;
        padding-bottom: 8px;
    }
`;

export const FooterLink = styled(Link)`
    color: #999999;
    display: block;
    cursor: pointer;

    &:before {
        content: '${props => props.content}';
        font-size: 8px;
        font-weight: 400;
        text-transform: uppercase;
        color: #393939;
        letter-spacing: 0.1rem;
        position: relative;
        display: inline-block;
        vertical-align: text-top;
        margin-right: 4px;
    }
    
    &:hover{
        text-decoration: underline;
    }
`;