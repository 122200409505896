import React from 'react';
import Img from '../../images/max.png';
import { SiBlender, SiAdobephotoshop, SiAdobeillustrator, SiAdobeaftereffects, SiSketch, SiFigma, SiFramer, SiCss3, SiHtml5 } from 'react-icons/si';
import { GiGamepad, GiWineBottle, GiCookingPot, GiRunningShoe, GiFilmProjector, GiSwimfins, GiSkis } from 'react-icons/gi';
import {
    AboutContainer,
    AboutWrapper,
    AboutH1,
    AboutImage,
    AboutTop,
    AboutSkills,
    AboutCopy,
    AboutWork,
    AboutPlace
} from './AboutElements';

const About = () => {
    return (
        <AboutContainer id="about">
            <AboutH1>About</AboutH1>
            <AboutWrapper>
                <AboutImage style={{backgroundImage: `url(${Img})`}} content=""/>
{/*                 <AboutTop content="Info">Max Flick<br/><br/>1986/05/14<br/><br/>Stockholm</AboutTop>
                <AboutTop content="Contact">max@flick.se<br/><br/>070 407 30 08<br/><br/>Linkedin</AboutTop> */}
                <AboutTop content="Name">Max Flick</AboutTop>
                <AboutTop content="Born">1986/05/14</AboutTop>
                <AboutTop content="City">Stockholm</AboutTop>
                <AboutTop content="Phone">070 407 30 08</AboutTop>
                <AboutTop content="Mail">max@flick.se</AboutTop>
                <AboutTop content="SoMe">Linkedin</AboutTop>
                <AboutWork content="Experience" gridColumn="1 / span 3">
                    <AboutPlace content="2017—">Product Designer at Tele2</AboutPlace>
                    <AboutPlace content="2017">Digital Designer at myTaxi</AboutPlace>
                    <AboutPlace content="2016—2017">Digital Designer at Scream</AboutPlace>
                    <AboutPlace content="2016">Digital Designer at LRF Media</AboutPlace>
                    <AboutPlace content="2014—2016">Creative Producer at OMD</AboutPlace>
                </AboutWork>
                <AboutWork content="Education" gridColumn="4 / span 3">
                    <AboutPlace content="2021 (Ongoing)">UX Upskill at Hyper Island </AboutPlace>
                    <AboutPlace content="2011—2013">Design and Advertising at Forsbergs</AboutPlace>
                    <AboutPlace content="2008—2011">Economy and Marketing at Göteborgs Universitet </AboutPlace>
                </AboutWork>
                <AboutSkills content="Toolbox">
                    <SiSketch />
                    <SiFigma />
                    <SiFramer />
                    <SiBlender />
                    <SiCss3 />
                    <SiHtml5 />
                    <SiAdobephotoshop />
                    <SiAdobeillustrator />
                    <SiAdobeaftereffects />
                </AboutSkills>
                <AboutCopy content="About me">Hi, my name is Max! After realizing they all lied when saying "if you study economy you can become whatever you want" I started studying design at Forsbergs. This year I will deepen my UX skills at Hyper Island. <br/><br/>I live in Stockholm with my wife Anna-Karin (pictured above in one of my favourite parts of Sweden, Bohuslän) and our dog Mortiz. </AboutCopy>
                <AboutSkills content="Hobbies">
                    <GiGamepad />
                    <GiFilmProjector />
                    <GiRunningShoe />
                    <GiSwimfins />
                    <GiSkis />
                    <GiCookingPot />
                    <GiWineBottle />
                </AboutSkills>
            </AboutWrapper>
        </AboutContainer>
    )
}

export default About;
