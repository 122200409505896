import styled from 'styled-components';
import { motion } from 'framer-motion';

export const GridWrapper = styled(motion.div)`
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));


    @media screen and (min-width: 769px){
        grid-gap: 64px;
        width: 1200px;
        max-width: calc(100% - 128px);
        position: relative;
        margin: 96px auto;
    }
   
    @media screen and (max-width: 768px){
        grid-gap: 16px;
        padding: 16px;
    }

    @media screen and (max-width: 480px){
        grid-gap: 16px;
        padding: 16px;
    }

`;