import React from 'react';
import{
    FooterContainer,
    FooterWrap,
    FooterContent,
    FooterLink
} from './FooterElements';
import { projectData } from '../InfoSection/Data';
import { AnimatePresence } from 'framer-motion';

const Footer = () => {

    return (
        <>
        <AnimatePresence>
        <FooterContainer initial={{ opacity: 0, y: 20}} animate={{ opacity: 1 , y: 0}} exit={{ opacity: 0, y: -20}} >
            <FooterWrap>
                <FooterContent content="About" gridColumn="span 6">
                Max Flick<br></br>1986/05/14<br></br>Stockholm
                </FooterContent>
                 <FooterContent content="Contact" gridColumn="span 6">
                    Linkedin<br></br>max@flick.se<br></br>070 407 30 08
                </FooterContent>
                <FooterContent content={'Projects ' + '(' + (projectData.length) + ')'} gridColumn="span 12">
                    {projectData.map((project, index) => {
                        return (
                            <FooterLink 
                                to={`/project/${index + 1}`}
                                content={'0' + (index + 1)}
                                >
                                {project.headLine}
                            </FooterLink>
                    )})}
                </FooterContent>
                <FooterContent content="Credits" gridColumn="span 12">Special thank to Brian Design for his amazing React tutorials and my colleague (and friend) Mike who helped me the many times I got stuck developing this page.
                </FooterContent>
               
            </FooterWrap>
        </FooterContainer>
        </AnimatePresence>
        </>
    )
}

export default Footer;
